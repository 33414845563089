import React from 'react';

import {createBrowserRouter, RouterProvider, Outlet} from 'react-router-dom';

// pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Rules from './pages/Rules';
import Policy from './pages/Policy';
import Returns from './pages/Returns';
import Shipping from './pages/Shipping';
import Products from './pages/Products';
import ProductDetails from './pages/ProductDetails';
import Search from './pages/Search';

// components
import Header from './components/Header';
import Footer from './components/Footer';

// layout
const Layout = () => {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/about', element: <About /> },
      { path: '/contact', element: <Contact /> },
      { path: '/rules', element: <Rules /> },
      { path: '/policy', element: <Policy /> },
      { path: '/returns', element: <Returns /> },
      { path: '/shipping', element: <Shipping /> },
      { path: '/products/:id', element: <Products /> },
      { path: '/product/:id', element: <ProductDetails /> },
      { path: '/search', element: <Search /> },
    ]
  },
]);

const App = () => {
  return <div><RouterProvider router={router} /></div>;
};

export default App;
