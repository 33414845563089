import React from 'react';

const Policy = () => {
  return (
  <section>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Polityka prywatności i plików cookies</h2>
      <p className='mb-2'>POLITYKA PRYWATNOŚCI SKLEPU INTERNETOWEGO NEW STORY</p>
      <p className='mb-2'>1. Informacje ogólne</p>
      <p className='mb-2'>Niniejsza Polityka Prywatności określa zasady przetwarzania Twoich danych osobowych w związku z korzystaniem z naszego Sklepu Internetowego New Story, który dostępny jest pod adresem http://sklep.new-story.pl</p>
      <p className='mb-2'>2. Administrator</p>
      <p className='mb-2'>Administratorem Danych Osobowych jest: Fundacja New Story, ul. Marii Konopnickiej 13; 87-100 Toruń; NIP: 9562315251</p>
      <p className='mb-2'>Możesz się skontaktować z Administratorem w wybrany przez Ciebie sposób:
      <ul className='mb-2 list-disc pl-6'>
        <li>wysyłając list na adres: ul. Marii Konopnickiej 13; 87-100 Toruń,</li>
        <li>wysyłając wiadomość e-mail na adres: zamówienia@new-story.pl.</li>
      </ul>
      Jeżeli w niniejszej Polityce Prywatności używamy pojęcia „Sklep” lub „Strona Internetowa” to odnoszą się one właśnie do Sklepu Internetowego New Story (http://sklep.new-story.pl) i związanych z nim funkcjonalności.
      </p>
      <p className='mb-2'>3. Cele i podstawy przetwarzania</p>
      <p className='mb-2'>Możemy przetwarzać Twoje dane osobowe (m.in. imię i nazwisko, adres zamieszkania, adres e-mail, numer telefonu) w różnych celach i w różnym zakresie, w zależności od tego, w jaki sposób korzystasz z naszego</p>
      <p className='mb-2'>Sklepu i jakich funkcjonalności używasz:</p>
      <p className='mb-2'>1) Jeżeli odwiedzasz naszą Stronę Internetową (Sklep) i przeglądasz znajdujące się na niej treści lub korzystasz z formularza kontaktowego albo wysyłasz nam wiadomość na podany w ramach Sklepu adres e-mail, przetwarzamy Twoje dane osobowe w następujących celach:</p>
      <p className='mb-2'>a. w celu zapewnienia prawidłowego działania Sklepu, monitorowania ruchu, weryfikacji sposobu korzystania przez osoby odwiedzające stronę internetową oraz ewentualnego przeciwdziałania działaniom użytkowników niezgodnym z Regulaminem Sklepu lub powszechnie obowiązującymi przepisami prawa. Dane te zapisujemy w szczególności w ramach plików cookies oraz logów dotyczących strony internetowej. Prawną podstawą do przetwarzania danych w tym zakresie jest przepis art. 6 ust. 1 lit. f) Rozporządzenia, umożliwiający przetwarzanie danych w sytuacji gdy jest to niezbędne do realizacji celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią, przy czym prawnie uzasadnionym interesem administratora w tym przypadku jest zapewnienie prawidłowego działania strony, prowadzenie statystyk dotyczących działania Strony Internetowej, przeciwdziałanie oszustwom i naruszeniom Regulaminu lub przepisów prawa,</p>
      <p className='mb-2'>b. w celu kontaktowania się z osobami, które korzystają z formularzy kontaktowych na Stronie Internetowej lub wysyłają wiadomości za pośrednictwem poczty elektronicznej – prawną podstawą do przetwarzania danych w tym zakresie jest przepis art. 6 ust. 1 lit. f) Rozporządzenia, umożliwiający przetwarzanie danych w sytuacji, gdy jest to niezbędne do realizacji celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią. Prawnie uzasadnionym interesem administratora w tym przypadku jest konieczność przetwarzania danych w celu kontaktu z osobami i udzielenia im odpowiedzi na zadane pytania,</p>
      <p className='mb-2'>c. w celu prowadzenia działań marketingowych dotyczących produktów i usług Administratora. W takim wypadku dane osobowe przetwarzane są na podstawie przepisu art. 6 ust. 1 lit. f) Rozporządzenia, umożliwiającego przetwarzanie danych w sytuacji, gdy jest to niezbędne do realizacji celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią. Prawnie uzasadnionym interesem Administratora jest w tym przypadku właśnie prowadzenie działań marketingowych. Działania te mogą obejmować np. umożliwienie zapisu na newsletter i wysyłkę takiego newslettera. Niezależnie od tego, w sytuacji, gdy przepisy prawa wymagają wyrażenia przez osobę, której dane dotyczą odrębnej zgody, np. w celu przedstawienia jej oferty za pomocą środków komunikacji elektronicznej lub urządzeń telekomunikacyjnych, Administrator pozyskuje takie zgody stosownie do wymogów wynikających z przepisów prawa. Zgoda taka jest wymagana na podstawie przepisów Prawa Telekomunikacyjnego właśnie w odniesieniu do wysyłki wiadomości marketingowych, takich jak newsletter.</p>
      <p className='mb-2'>2) Jeżeli dokonujesz zakupu oferowanych przez nas produktów w Sklepie, przetwarzamy Twoje dane osobowe w następujących celach:</p>
      <p className='mb-2'>a. w celu zawarcia oraz wykonania postanowień umowy sprzedaży, w tym w szczególności zamówienia produktu, dostawy i umożliwienia dokonania płatności, jak również ewentualnych dodatkowych działań związanych z procesem zakupu, w zakresie i na zasadach określonych Regulaminie Sklepu New Story – prawną podstawą do przetwarzania danych w tym zakresie jest przepis art. 6 ust. 1 lit. b) Rozporządzenia umożliwiający przetwarzanie danych w sytuacji gdy jest to niezbędne zawarcia oraz do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy,</p>
      <p className='mb-2'>b. w celu realizacji obowiązków nałożonych na Administratora na podstawie przepisów prawa, w tym w szczególności w zakresie przepisów podatkowych – prawną podstawą do przetwarzania danych w tym zakresie jest przepis art. 6 ust. 1 lit. c) Rozporządzenia umożliwiający przetwarzanie danych w sytuacji, gdy jest to niezbędne do wypełnienia obowiązku prawnego ciążącego na administratorze,</p>
      <p className='mb-2'>c. w celu zapobiegania nadużyciom i oszustwom oraz ewentualnemu dochodzeniu roszczeń lub obrony przed roszczeniami – stanowi to prawnie uzasadniony interes administratora, a tym samym dane osobowe przetwarzane są na podstawie przepisu art. 6 ust. 1 lit. f) Rozporządzenia, umożliwiającego przetwarzanie danych w sytuacji, gdy jest to niezbędne do realizacji celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią.</p>
      <p className='mb-2'>3) Jeżeli jesteś osobą, która występuje w imieniu naszego Klienta, będącego osobą prawną i w związku z tym kontaktujesz się z nami, działając jako przedstawiciel naszego Klienta (w szczególności jako członek zarządu, wspólnik reprezentant, pracownik lub współpracownik), przetwarzamy Twoje dane osobowe w celu zawarcia oraz wykonania umowy pomiędzy Sklepem a Klientem – w takim przypadku prawną podstawą do przetwarzania danych jest przepis art. 6 ust. 1 lit f) Rozporządzenia, który wskazuje, że przetwarzanie danych jest dopuszczalne w sytuacji gdy jest to niezbędne do realizacji celów wynikających z prawnie uzasadnionych interesów realizowanych przez administratora lub przez stronę trzecią. Prawnie uzasadnionym interesem administratora w tym przypadku jest właśnie wykonanie umowy pomiędzy nami a Klientem (osobą prawną), co wymaga kontaktowania się z przedstawicielami Klienta.</p>
      <p className='mb-2'>4) Jeżeli zakładasz w ramach Sklepu Konto i podajesz w związku z tym swoje dane osobowe, przetwarzamy Twoje dane osobowe w celu zawarcia i wykonania umowy o świadczenie usług drogą elektroniczną – prawną podstawą do przetwarzania danych w tym zakresie jest przepis art. 6 ust. 1 lit. b) Rozporządzenia umożliwiający przetwarzanie danych w sytuacji gdy jest to niezbędne zawarcia oraz do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy.</p>
      <p className='mb-2'>5) Posiadamy również fanpage na Facebooku, Instagramie, YouTube oraz TikToku dotyczące naszego Sklepu. W związku z tym możemy przetwarzać dane osobowe osób wchodzących na fanpage, dokonujących polubień postów, komentujących posty lub obserwujących fanpage. W ramach tych portali społecznościowych możemy kierować do Ciebie treści marketingowe (wyświetlać posty oznaczone jako sponsorowane) zgodnie z regulaminem Facebooka, Instagrama albo TikToka, co również stanowi nasz prawnie uzasadniony cel jako administratora danych. Nasz fanpage na Facebooku, połączony jest z naszą stroną internetową (Sklepem) za pomocą wtyczek, umieszczonych w ramach Sklepu. Oznacza to, że wchodząc na naszą stronę internetową Facebook może uzyskać informacje, że ją odwiedziłeś. Dane związane z działalnością naszego fanpage na Facebooku, Instagramie i TiktToku oraz wtyczki Facebooka na Stronie Internetowej przetwarzamy na podstawie przepisu art. 6 ust. 1 lit f) Rozporządzenia, czyli na podstawie naszego prawnie uzasadnionego interesu.</p>
      <p className='mb-2'>4. Odbiorcy danych</p>
      <p className='mb-2'>W celu prawidłowego świadczenia przez nas usług korzystamy z pomocy niektórych podmiotów trzecich. Dlatego podane przez Ciebie dane osobowe mogą zostać przekazane:
      <ul className='mb-2 list-disc pl-6'>
      <li>Podmiotowi odpowiedzialnemu za hosting naszej strony internetowej, tj. nazwa.pl sp. z o.o. z siedzibą w Krakowie,</li>
      <li>podmiotowi świadczącemu na naszą rzecz usługi analityczne oraz marketingowe (Google Analytics oraz Google Ads) – Google Ireland Ltd. oraz Google LLC z siedzibą w Mountain View w Kalifornii. Prawną podstawą do przekazania danych do Stanów Zjednoczonych są Standardowe Klauzule Umowne, zatwierdzone przez Komisję Europejską, znajdujące się w umowie zawartej pomiędzy Google Ireland Ltd. oraz Google LLC,</li>
      <li>podmiotowi, który zarządza portalami społecznościowymi Facebook i Instagram – Facebook Ireland Limited z siedzibą w Dublinie, Irlandii ((jeżeli wchodzisz na nasz fanpage w ramach tych platform lub odwiedzasz naszą stronę internetową),</li>
      <li>podmiotowi, który zarządza portalem TikTok – TikTok Technology Ltd. z siedzibą w Dublinie, Irlandii (jeżeli wchodzisz na nasz fanpage w ramach tej aplikacji),</li>
      <li>podmiotowi, który zarządza portalem Youtube – Google Ireland Ltd. oraz Google LLC z siedzibą w Mountain View w Kalifornii (jeżeli wchodzisz na nasz fanpage w ramach tej platformy),.</li>
      <li>operatorom płatności, którzy umożliwiają dokonywanie płatności elektronicznych w ramach sklepu:<br/>
      - serwis PayPal – PayPal (Europe) S.a.r.l. et Cie, S.C.A z siedzibą w Luksemburgu,<br/>
      - serwis mBank – mBank S.A. z siedzibą w Warszawie,<br/>
      - serwis Stripe – Stripe Payments Europe Limited z siedzibą w Dublinie, Irlandii,<br/>
      </li>
      <li>podmiotowi odpowiadającemu za wysyłkę newslettera – Freshmail sp. z.o.o. z siedzibą w Krakowie,</li>
      <li>podmiotowi świadczącemu na naszą rzecz usługi z zakresu IT - EXOLVO Aleksander Sztramski z siedzibą w Toruniu,</li>
      <li>podmiotowi świadczącemu na naszą rzecz usługi z zakresu księgowości - Zygmuntowscy spółka z o. o.  z siedzibą w Toruniu,</li>
      <li>dostawcom usług kurierskich i przewozowych.</li>
      </ul>
      </p>
      <p className='mb-2'>5. Okres przechowywania danych osobowych</p>
      <p className='mb-2'>Twoje dane przechowujemy tylko przez niezbędny okres, tj.</p>
      <p className='mb-2'>1) jeżeli jesteś osobą, która weszła na naszą stronę internetową, przetwarzamy Twoje dane osobowe tak długo jak korzystasz ze strony oraz przez okres do 12 miesięcy po jej opuszczeniu,</p>
      <p className='mb-2'>2) jeżeli jesteś osobą, która napisała do nas za pomocą formularza kontaktowego znajdującego się na naszej stronie internetowej lub za pomocą poczty elektronicznej – przetwarzamy Twoje dane osobowe tak długo jak długo trwa kontakt pomiędzy nami a Tobą. Po jego zakończeniu oraz upływie 3 miesięcy, usuwamy dane osobowe, które pozyskaliśmy w ten sposób,</p>
      <p className='mb-2'>3) jeżeli jesteś osobą, która zakupiła od nas produkt, oferowany w ramach naszego Sklepu, przetwarzamy Twoje dane osobowe przez okres 6 lat od zakończenia roku, w którym dokonałeś zakupu,</p>
      <p className='mb-2'>4) jeżeli jesteś osobą, która zawarła z nami umowę o świadczenie usług drogą elektroniczną (założyła Konto) jako Klient, Twoje dane są przetwarzane przez okres obowiązywania umowy o świadczenie usług elektronicznych, zawartej pomiędzy nami a Tobą oraz po jej zakończeniu przez okres do upływu 6 lat od zakończenia roku, w którym zakończyłeś korzystania z usługi, zgodnie z terminami przedawnienia roszczeń. Dotyczy to również przedstawicieli Klienta, którzy są osobami wyznaczonymi w ramach organizacji Klienta do kontaktu z nami,</p>
      <p className='mb-2'>5) jeżeli wyraziłeś wolę otrzymywania od nas newslettera lub innych materiałów marketingowych przetwarzamy Twoje dane osobowe w tym celu do momentu aż wypiszesz się z naszej listy mailingowej lub do zakończenia prowadzenia przez nas działań promocyjnych,</p>
      <p className='mb-2'>6) jeżeli obserwujesz nas w ramach portalu społecznościowego, lubisz nasze posty lub kontaktujesz się z nami za pośrednictwem naszego fanpage, przetwarzamy Twoje dane osobowe przez okres trwania interakcji pomiędzy Tobą a naszym fanpage oraz do upływu roku po zakończeniu działania danej interakcji.</p>
      <p className='mb-2'>6. Twoje prawa</p>
      <p className='mb-2'>Informujemy, że przysługują Ci następujące prawa:</p>
      <ul className='mb-2 list-disc pl-6'>
      <li>prawo dostępu do dotyczących Ciebie danych osobowych,</li>
      <li>prawo do sprostowania swoich danych osobowych,</li>
      <li>prawo do usunięcia swoich danych osobowych,</li>
      <li>prawo do ograniczenia przetwarzania danych osobowych,</li>
      <li>prawo do wniesienia sprzeciwu wobec przetwarzania – w zakresie w jakim przetwarzanie odbywa się na podstawie prawnie uzasadnionego interesu administratora,</li>
      <li>żądania przeniesienia danych, które Ciebie dotyczą,</li>
      <li>prawo wniesienia skargi do organu nadzorczego, którym jest Prezes Ochrony Danych Osobowych (na adres Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa).</li>
      </ul>
      <p className='mb-2'>Możesz w każdym czasie cofnąć zgodę na działania, które wiążą się z przetwarzaniem danych osobowych (np. wysyłkę newslettera), co nie wpłynie na prawidłowość przetwarzania danych przed cofnięciem zgody.</p>
      <p className='mb-2'>Twoje dane osobowe nie będą wykorzystywane w celu profilowania, w tym zautomatyzowanego podejmowania decyzji. Możemy kierować do Ciebie reklamy oparte o dotychczasowe Twoje działania w ramach Sklepu lub portali społecznościowych, z których korzystamy, lecz działania w tym zakresie stanowią standardowe działania marketingowe i nie są związane z zautomatyzowanym podejmowaniem decyzji lub innymi działaniami, które mogą mieć na Ciebie istotny wpływ.</p>
      <p className='mb-2'>Administrator zobowiązuje się do dołożenia starań w celu zachowania należytego zabezpieczenia Twoich danych osobowych Klienta oraz do zachowania tajemnicy związanej z Twoimi danymi osobowymi.</p>
      <p className='mb-2'>Podanie danych osobowych może być warunkiem założenia Konta w ramach Sklepu lub warunkiem dokonania zakupu Produktu, który jest oferowany w ramach Sklepu.</p>
      <p className='my-6 text-xl text-center xl:text-left'>Pliki cookies</p>
      <p className='mb-2'>Pliki cookies to małe pliki, umożliwiające lub ułatwiające korzystanie z niektórych funkcji strony internetowej. Mogą być zapisywane na Twoim urządzeniu bezpośrednio przez nas lub przez podmioty trzecie, z którymi współpracujemy. W ramach korzystania z plików cookies możemy przetwarzać Twoje dane osobowe, w szczególności takie jak:</p>
      <ul className='mb-2 list-disc pl-6'>
      <li>adres IP,</li>
      <li>historia korzystania przez Ciebie ze strony internetowej,</li>
      <li>informacje o używanym przez Ciebie urządzeniu lub oprogramowaniu.</li>
      </ul>
      <p className='mb-2'>Stosowane przez nas pliki cookies są związane z funkcjonowaniem naszej strony internetowej, służą do kontroli ruchu na naszej stronie internetowej, tworzenia statystyk w zakresie korzystania ze strony przez jej użytkowników, podejmowaniu działań marketingowych, zapobieganiu błędom i usterkom technicznym, zapewnieniu bezpieczeństwa strony czy też zapobieganiu nadużyciom i naruszeniom prawa.</p>
      <p className='mb-2'>Wyróżnia się następujące pliki cookies:</p>
      <p className='mb-2'>Cookies sesyjne: są przechowywane na Twoim urządzeniu w czasie, w którym korzystasz z naszej strony internetowej (i są usuwane po zamknięciu przeglądarki). Cookies sesyjne umożliwiają prawidłowe korzystanie z naszej strony internetowej. Ich zablokowanie może skutkować wystąpieniem błędów lub uniemożliwieniem skorzystania z naszej strony.</p>
      <p className='mb-2'>Cookies trwałe: są przechowywane na Twoim urządzeniu do momentu ich skasowania lub do momentu ich wygaśnięcia.</p>
      <p className='mb-2'>Wykorzystanie plików cookies opiera się o Twoją zgodę, wyrażoną stosownie do treści przepisu art. 173 § 1 ustawy Prawo Telekomunikacyjne. Brak takiej zgody lub późniejsze usunięcie plików cookies może skutkować niemożnością skorzystania z funkcjonalności Sklepu.</p>
      <p className='mb-2'>Masz możliwość ograniczenia lub wyłączenia plików cookies w ramach Twojego urządzenia. Ustawienia dotyczące korzystania z plików cookies znajdują się w ustawieniach Twojej przeglądarki internetowej. Przeglądarki internetowe umożliwiają wyłączenie wszystkich plików cookies lub ich części (np. pochodzącej od podmiotów trzecich). W przypadku wyłączenia plików cookies w części, na Twoim urządzeniu mogą zostać zapisane pliki cookies generowane przez naszą stronę, umożliwiające prawidłowe działanie strony. Będzie to skutkowało jednak tym, że nie będziesz mógł skorzystać z wszystkich funkcjonalności Sklepu.</p>
      <p className='mb-2'>Pamiętaj jednak, że w przypadku ograniczenia stosowania plików cookies, korzystanie z poszczególnych naszych usług może mieć charakter ograniczony, a w niektórych wypadkach może okazać się niemożliwe.</p>
      <p className='mb-2'>W sprawach nieuregulowanych Polityką prywatności zastosowanie mają postanowienia Kodeksu cywilnego i odpowiednich ustaw prawa polskiego, a także prawa Unii Europejskiej, w szczególności RODO (Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE).</p>
    </div>
  </section>
  )
};

export default Policy;
