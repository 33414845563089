import React from 'react';
import { Link } from 'react-router-dom';

import { FaFacebook, FaGlobe, FaTelegram, FaViber } from 'react-icons/fa';

const Footer = () => {
  return <footer className='pt-16 bg-primary'>
    <div className='container mx-auto'>
      <div className='text-center'>
        <h2 className='h2 uppercase mb-6 font-semibold'>Zapisz się do naszego newslettera</h2>
        <p className='text-white/70'>Spraw, by promocje znajdowały Ciebie</p>
      </div>
      <form className='w-full max-w-3xl mx-auto flex flex-col md:flex-row gap-5 my-14'>
        <input className='input' type='email' placeholder='Twój adres e-mail' />
        <button className='btn btn-accent min-w-[150px]'>Zapisz się</button>
      </form>
      <div className='text-base text-white/60 flex gap-x-6 max-w-max mx-auto mb-9'>
        <Link to='/about' className='hover:text-white transition-all'>O nas</Link>
        <Link to='/contact' className='hover:text-white transition-all'>Kontakt</Link>
        <Link to='/rules' className='hover:text-white transition-all'>Regulamin</Link>
        <Link to='/policy' className='hover:text-white transition-all'>Polityka prywatności</Link>
        <Link to='/returns' className='hover:text-white transition-all'>Reklamacje i zwroty</Link>
        <Link to='/shipping' className='hover:text-white transition-all'>Opcje transportowe</Link>
      </div>
      <div className='flex gap-x-6 max-w-max mx-auto text-lg mb-16 text-white/60'>
        <a href='http://new-story.pl/' className='hover:text-white transition-all'><FaGlobe /></a>
        <a href='https://fb.me/FundacjaNewStory' className='hover:text-white transition-all'><FaFacebook /></a>
        <a href='https://t.me/+48535328635' className='hover:text-white transition-all'><FaTelegram /></a>
        <a href='https://invite.viber.com/?g=Rf6vFQd8CE_yJt47biLmp4jV1vIYy9ZQ&lang=ru' className='hover:text-white transition-all'><FaViber /></a>
      </div>
    </div>
    <div className='py-10 border-t border-t-white/10'>
      <div className='container mx-auto'>
        <div className='text-center text-sm text-white/60'>
          <span>&copy; 2024 Fundacja New Story</span>
          <span className='px-12'>wykonaie <a href='https://exolvo.pl'>EXOLVO</a></span>
        </div>
      </div>
    </div>
  </footer>;
};

export default Footer;
