import React from 'react';

import Hero from '../components/Hero';
import LatestProducts from '../components/LatestProducts';

const Home = () => {
  return (
  <section>
    <Hero />
    <LatestProducts />
  </section>
  )
};

export default Home;
