import React, { useContext } from 'react';
import { CartContext } from '../context/CartContext';

const Qty = ({item}) => {
  const {handleInput} = useContext(CartContext);
  return <div className='flex gap-x-6 items-center text-primary'>
    <input onBlur={(e)=>handleInput(e, item.id)} className='text-primary placeholder:text-primary h-12 rounded-md p-4 w-[120px] outline-accent' type="number" placeholder={item.amount} />
  </div>;
};

export default Qty;
