import React, {useState, useEffect} from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../hooks/useFetch';

import CategoryNav from '../components/CategoryNav';
import Product from '../components/Product';

const Products = () => {
  const {id} = useParams('id');
  //console.log(id);
  const {data} = useFetch(`/products?populate=*&filters[categories][documentId][$eq]=${id}`);
  const {data: category} = useFetch(`/categories/${id}`);
  const [title, setTitle] = useState(null);
/*
  useEffect(()=>{
    if (data)
      setTitle(data[0].categories[0].title);
    console.log(data);
  },[data]);
*/
  useEffect(()=>{
    if (category)
      setTitle(category.title);
    //console.log(category);
  },[category]);
  return (
  <div className='mb-16 pt-40 lg:pt-0'>
    <div className='container mx-auto'>
      <div className='flex gap-x-[30px]'>
        <CategoryNav />
        <main>
          <div className='py-3 text-xl uppercase text-center lg:text-left'>{title}</div>
          <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-[15px] md:gap-[30px]'>
            {data?.map((product)=>{
              return <Product product={product} key={product.documentId} />
            })}
          </div>
          {data?.length === 0 && (
            <div className='py-3 text-center lg:text-left'>Obecnie brak produktów w wybranej kategorii.</div>
          )}
        </main>
      </div>
    </div>
  </div>);
};

export default Products;
