import React, { useContext } from 'react';

import {IoArrowForward, IoCartOutline, IoClose} from 'react-icons/io5';
import {CartContext} from '../context/CartContext';
import CartItem from '../components/CartItem';

import {loadStripe} from '@stripe/stripe-js';
import {request} from '../request';

const Cart = () => {
  const {setIsOpen, cart, total, clearCart } = useContext(CartContext);
  const stripePromise = loadStripe('pk_test_51Q9uGGAnsRzs1DQz8rGYmXxWHNPzAHnPwQ4G9PKOR0ltRP60xftd83NifgjXnIOtINxOl6QHmDotQaufoRe6rAHF00RJn6gkoB');

  const handlePayment = async ()=>{
    try {
      const stripe = await stripePromise;
      const res = await request.post('/orders', {
        cart,
      });
      await stripe.redirectToCheckout({
        sessionId: res.data.stripeSession.id,
      });
    } catch (error) {
      console.log(error);
    }
  };
  return <div className='w-full h-full px-4 text-white'>
    <div className='overflow-y-auto overflow-x-hidden h-[75%]'>
      <div
       onClick={()=>setIsOpen(false)}
       className='text-4xl w-20 h-[98px] flex justify-start items-center cursor-pointer'>
        <IoClose />
      </div>
      <div className='flex flex-col gap-y-10 px-2'>
        {cart.map(item=>{
          return <CartItem item={item} key={item.id} />;
        })}
      </div>
    </div>
    {cart.length >=1 && (
    <div className='px-6 py-10 flex flex-col'>
      <div className='flex justify-between text-2xl'>
        <div>Suma</div>
        <div>{total} PLN</div>
      </div>
    </div>
    )}
    <div className='px-6'>
    {cart.length >= 1 ? (
      <div className='flex justify-between gap-x-4'>
        <button onClick={clearCart} className='btn btn-accent hover:btn-accent-hover text-primary'>Wyczyść koszyk</button>
        <button onClick={handlePayment} className='btn btn-accent hover:btn-accent-hover text-primary flex-1 px-2 gap-x-2'>Płatność <IoArrowForward /></button>
      </div>) : (
      <div className='h-full absolute top-0 left-0 right-0 flex flex-col items-center justify-center -z-10 text-white/50'>
        <div className='text-2xl'>Koszyk jest pusty.</div>
        <div className='text-6xl'><IoCartOutline /></div>
      </div>
      )}
    </div>
  </div>;
};

export default Cart;
