import React, {useContext} from 'react';

import {Link} from 'react-router-dom';

import {IoTrash} from 'react-icons/io5';
import Qty from '../components/Qty';
import { CartContext } from '../context/CartContext';

const CartItem = ({item}) => {
  const {removeFromCart} = useContext(CartContext);
  return <div className='flex gap-x-8'>
    <Link to={`product/${item.id}`} className='w-[70px] h-[70px]'>
      <img src={`${process.env.REACT_APP_ASSETS_URL}${item.image.url}`} alt='' />
    </Link>
    <div className='flex-1'>
      <div className='flex gap-x-4 mb-3 justify-between'>
        <Link to={`product/${item.id}`}>{item.title}</Link>
        <div
         onClick={()=>removeFromCart(item.id)}
         className='text-[24px] cursor-pointer hover:text-accent transition-all'>
          <IoTrash />
        </div>
      </div>
      <div className='flex gap-x-12 items-center'>
        <div className='flex gap-x-4 mb-2'>
          <Qty item={item} />
        </div>
        <div className='text-accent text-xl'>{item.amount * item.price} PLN</div>
      </div>
      <div>
          <span className='text-accent'>
          {item.price} PLN/szt.
          </span>
        </div>
    </div>
  </div>;
};

export default CartItem;
