import React from 'react';

const Rules = () => {
  return (
  <section>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>REGULAMIN SKLEPU INTERNETOWEGO NEW STORY (sklep.new-story.pl) ORAZ ŚWIADCZENIA USŁUG DROGĄ ELEKTRONICZNĄ (dalej jako: „Regulamin”)</h2>
      <p className='mb-2'>§ 1. POSTANOWIENIA OGÓLNE</p>
      <p className='mb-2'>1. Niniejszy Regulamin określa ogólne warunki korzystania ze Sklepu, w szczególności warunki zawierania Umowy Sprzedaży, warunki składania zamówień na Towary oferowane przez Sprzedawcę, dostarczenia zamówionych Towarów Klientowi, uiszczania przez Klienta Ceny sprzedaży takich Towarów oraz uprawnienia Klienta do rezygnacji z Zamówienia i odstąpienia od umowy, a także zasady składania i rozpatrywania reklamacji.</p>
      <p className='mb-2'>2. Ilekroć w Regulaminie użyto niżej wymienionych zwrotów pisanych wielką literą, należy je rozumieć w podanym niżej znaczeniu, chyba że z kontekstu ich użycia wyraźnie wynika co innego:</p>
      <p className='mb-2'>a) Cena - określona w złotych polskich lub w innej walucie (w przypadku gdy miejsce pobytu Kupującego znajduje się poza terytorium Rzeczypospolitej Polskiej), wysokość wynagrodzenia brutto (uwzględniająca podatek) należnego tytułem przeniesienia własności Produktu na Klienta zgodnie z Umową Sprzedaży. Cena nie zawiera kosztów dostawy, chyba że warunki Promocji stosowanej przez Sklep stanowią inaczej;</p>
      <p className='mb-2'>b) Dzień roboczy - dzień tygodnia od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy;</p>
      <p className='mb-2'>c) Hasło - ciąg znaków alfanumerycznych, konieczny do dokonania autoryzacji w trakcie uzyskiwania dostępu do Konta, który jest określony przez Klienta podczas tworzenia Konta. Klient jest zobowiązany do zachowania Hasła w ścisłej poufności (do nieujawniania go jakimkolwiek osobom trzecim). Konsekwencje ujawnienia loginu i hasła osobom trzecim przez Klienta ponosi Klient. Hasło może być zmienione w ustawieniach Konta. Sprzedawca może udostępnić Klientowi możliwość tzw. odzyskania zapomnianego Hasła w sposób określony w ramach Sklepu;</p>
      <p className='mb-2'>d) Formularz Zamówienia - wzór dokumentu z rubrykami do wypełnienia, za pośrednictwem, którego Klient składa Zamówienie;</p>
      <p className='mb-2'>e) Klient/Klienci - osoba fizyczna o pełnej lub ograniczonej zdolności do czynności prawnych albo osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, prawidłowo reprezentowana przez właściwą osobę fzyczną, która korzysta ze Strony w tym w szczególności złoży Zamówienie;.</p>
      <p className='mb-2'>f) Kod rabatowy - kod rabatowy wydawany przez Sprzedawcę uprawniający do nabycia Towarów oferowanych do sprzedaży w Sklepie po promocyjnej cenie lub przy uwzględnieniu określonego rabatu procentowego lub kwotowego. Klient może otrzymać kod rabatowy na odrębnych zasadach ustalonych przez Sprzedawcę;</p>
      <p className='mb-2'>g) Konsument - Klient, który jest osobą fizyczną zawierającą ze Sprzedawcą Umowę Sprzedaży w celach niezwiązanych bezpośrednio z jej działalnością gospodarczą lub zawodową oraz osoba prowadząca jednoosobową działalność gospodarczą, dokonująca zakupów niezwiązanych z prowadzoną działalnością gospodarczą;</p>
      <p className='mb-2'>h) Konto - przestrzeń udostępniana Klientowi w ramach Sklepu zgodnie z Umową Sprzedaży, pozwalająca na korzystanie przez Klienta z dodatkowych funkcji/usług. Klient uzyskuje dostęp do Konta Klienta za pomocą Loginu i Hasła. Klient loguje się na swoje Konto po dokonaniu rejestracji na stronie internetowej Sklepu;</p>
      <p className='mb-2'>i) Koszyk - narzędzie systemowe (funkcjonalność) ułatwiające Klientom dokonywanie zakupów w Sklepie, w szczególności umożliwiające gromadzenie wybranych Towarów jednym miejscu, w tym ewentualnych kosztów wysyłki celem złożenia Zamówienia oraz, dla Klientów posiadających Konto, zapamiętanie Towarów dodanych do Koszyka również po zakończeniu sesji przeglądarki;</p>
      <p className='mb-2'>j) Login - adres e-mail Klienta podany podczas tworzenia Konta przez Klienta w ramach funkcjonowania Sklepu;</p>
      <p className='mb-2'>k) Newsleter - nieodpłatnie świadczona drogą elektroniczną za pośrednictwem wiadomości e-mail usługa, dzięki której Klient może otrzymywać od Sprzedawcy drogą elektroniczną uprzednio zamówione wiadomości dotyczące Sklepu, w tym informacje o ofertach, promocjach oraz nowościach w Sklepie;</p>
      <p className='mb-2'>l) Obsługa Klienta - jest to całość infrastruktury (społecznej i technicznej) stworzonej przez Sprzedawcę w celu komunikacji z Klientem, która odbywa przede wszystkim telefonicznie/mailowo/za pośrednictwem korespondencji pisemnej, za pomocą których Klient może uzyskać informacje na temat Towarów, Sklepu, Regulaminu oraz aktualnych Promocji;</p>
      <p className='mb-2'>m) Partner - podmiot, z którym Sprzedawca współpracuje, w celu realizacji Zamówień, w tym w szczególności przedsiębiorca, którego przedmiotem działalności jest dostawa przesyłek;</p>
      <p className='mb-2'>n) Promocje - szczególne warunki sprzedaży, uregulowane na zasadach wyrażonych w odrębnych regulaminach, dotyczące specjalnej oferty Sklepu obowiązującej w określonym czasie, z których Klient może skorzystać na zasadach tam określonych. W przypadku rozbieżności postanowień właściwego regulaminu Promocji z postanowieniami Regulaminu, pierwszeństwo mają postanowienia właściwego regulaminu Promocji;</p>
      <p className='mb-2'>o) Regulamin - niniejszy dokument określający prawa i obowiązki Klienta i Sprzedawcy;</p>
      <p className='mb-2'>p) Sklep - sklep internetowy NEW STORY prowadzony przez Sprzedawcę, za pośrednictwem którego Sprzedawca dokonuje sprzedaży Towarów oraz świadczenia Usług oferowanych przez Sprzedawcę;</p>
      <p className='mb-2'>q) Sprzedawca - Fundacja New Story, ul. Marii Konopnickiej 13; 87-100 Toruń; NIP: 9562315251, NIP 5611497191, REGON 341235555; REGON 363373317  wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego oraz do Rejestru Stowarzyszeń, innych organizacji społecznych i zawodowych, fundacji, ZOZ  Krajowego Rejestru Sądowego</p>
      <p className='mb-2'>r) Towar - rzecz ruchoma, prezentowana w ofercie Sklepu, mogąca być przedmiotem Umowy Sprzedaży, szczegółowo określona w Zamówieniu, ilekroć w Regulaminie jest mowa o Towarach, dotyczy to również towarów powstałych w wyniku realizacji Zamówień Zindywidualizowanych, chyba że co innego wynika z treści danego postanowienia;</p>
      <p className='mb-2'>s) Treść/Treści - elementy tekstowe, graficzne lub multimedialne (np. informacje o Towarach, zdjęcia Towaru, filmy promocyjne, opisy, logotypy, zdjęcia, komentarze etc.) w tym utwory w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz wizerunki osób fizycznych, jakie są zamieszczane i rozpowszechniane przez Sprzedawcę, kontrahentów Sprzedawcy, Klienta albo inną osobę korzystającą ze Sklepu;</p>
      <p className='mb-2'>t) Umowa Sprzedaży - umowa sprzedaży w rozumieniu przepisów Kodeksu cywilnego, zawierana z wykorzystaniem środków porozumiewania się na odległość i dotycząca sprzedaży przez Sprzedawcę na rzecz Klienta Towarów za zapłatą Ceny powiększonej o ewentualne koszty wysyłki;</p>
      <p className='mb-2'>u) Zamówienie - oświadczenie woli złożone przez Klienta Sprzedawcy, zgodnie z postanowieniami Regulaminu, za pośrednictwem formularza udostępnionego w ramach Sklepu określające Towary jakie kupuje Klient oraz dane Klienta konieczne dla zawarcia i wykonania Umowy Sprzedaży;</p>
      <p className='mb-2'>v) Zamówienie Zindywidualizowane (CUSTOM) - to zamówienie, przez które Klient kupuje Towar wykonany według specyfikacji (instrukcji, zaleceń, wytycznych etc.) Klienta i/lub Towar dostępny w Sklepie, zmodyfikowany poprzez naniesienie indywidualnych cech wskazanych przez Klienta tj. rzecz wyprodukowana według specyfikacji Klienta.</p>
      <p className='mb-2'>§ 2. OGÓLNE ZASADY KORZYSTANIA ZE SKLEPU</p>
      <p className='mb-2'>1) Akceptacja Regulaminu jest dobrowolna, ale konieczna w celu dokonania zakupu w Sklepie oraz utworzenia Konta Klienta.</p>
      <p className='mb-2'>2) Klient ma możliwość zmiany i korygowania danych wprowadzonych podczas tworzenia Konta w każdym czasie korzystając z opcji dostępnych w ramach Konta Klienta.</p>
      <p className='mb-2'>3) Do przeglądania Sklepu w tym przeglądania asortymentu Sklepu, niezbędne jest urządzenie końcowe z dostępem do sieci Internet i przeglądarką internetową typu Internet Explorer, Microsoft Edge, Google Chrome, Mozilla Firefox, Opera lub Safari w aktualnej wersji oraz z włączoną obsługą języka Javascript aktywne konto poczty elektronicznej (e-mail). Do korzystania z niektórych funkcjonalności Sklepu w tym rejestracji w Sklepie, a także składania Zamówień na Towary , niezbędne jest ponadto aktywne konto poczty elektronicznej (e-mail).</p>
      <p className='mb-2'>4) Korzystanie ze Sklepu oraz z Usług oferowanych za jego pośrednictwem oznacza wyrażenie zgody na warunki określone w niniejszym Regulaminie.</p>
      <p className='mb-2'>§ 3. OBSŁUGA KLIENTA</p>
      <p className='mb-2'>Obsługa Klienta udziela Klientom informacji w zakresie prowadzonej przez Sklep działalności, w Dni Robocze w godzinach od 9:00 do 15:00 pod numerem telefonu +48 536-700-235 oraz pod adresem e-mail: zamowienia@new-story.pl Opłata dla Klienta za połączenie telefoniczne pod wskazanym numerem jest równa opłacie za połączenie telefoniczne zgodnie z pakietem taryfowym dostawcy usług, z którego korzysta Klient.</p>
      <p className='mb-2'>§ 4. PRAWA WŁASNOŚCI INTELEKTUALNEJ</p>
      <p className='mb-2'>1. Wszelkie prawa do Sklepu, w tym majątkowe prawa autorskie, prawa własności intelektualnej do jego nazwy, jego domeny internetowej, oprogramowania, a także do wzorców, formularzy, logotypów, zdjęć etc. zamieszczanych na Stronie należą do Sprzedawcy, a korzystanie z nich może następować wyłącznie w sposób określony i zgodny z Regulaminem, a jeżeli wykracza poza granice Regulaminu, wyłącznie za uprzednią zgodą Sprzedawcy wyrażoną na piśmie pod rygorem nieważności.</p>
      <p className='mb-2'>2. Klient, umieszczając w ramach Sklepu jakiekolwiek Treści, w szczególności komentarze lub recenzje w jakimkolwiek miejscu Sklepu udziela niniejszym Sprzedawcy niewyłącznej, nieodpłatnej licencji na wykorzystywanie, utrwalanie w pamięci komputera, zmienianie, usuwanie, uzupełnianie, wykonywanie publiczne, wyświetlanie publiczne, zwielokrotnianie i rozpowszechnianie (w szczególności w Internecie) tych Treści, na terytorium całego świata. Prawo to obejmuje uprawnienie do udzielania sublicencji w zakresie uzasadnionym realizacją Umowy Sprzedaży na polach eksploatacji wskazanych powyżej, a także upoważnienie do wykonywania samodzielnie lub za pomocą podmiotów trzecich praw zależnych w zakresie opracowania, dostosowywania, przerabiania i tłumaczenia utworu w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</p>
      <p className='mb-2'>§ 5. SKŁADANIE ZAMÓWIEŃ I DOKONYWANIE PŁATNOŚCI</p>
      <p className='mb-2'>1. Klient może składać Zamówienia za pośrednictwem Sklepu przez 7 dni w tygodniu, 24 godziny na dobę.</p>
      <p className='mb-2'>2. Zamówienia realizowane są w godzinach pracy Sklepu (w Dni Robocze, od w godzinach od 9.00 do 15.00). Zamówienia złożone w Dzień Roboczy po godzinie 15.00, w soboty, niedziele lub święta, będą rozpatrywane następnego Dnia Roboczego.</p>
      <p className='mb-2'>3. Klient składający Zamówienie za pośrednictwem Sklepu, kompletuje Zamówienie wybierając Towar, którym jest zainteresowany. Dodanie Towaru do Zamówienia następuje przez wybór przycisku „dodaj do Koszyka”. Klient po skompletowaniu całości Zamówienia, przechodzi do Koszyka, gdzie przez wybór przycisku „przejdź do realizacji zamówienia”, przechodzi do formularza Zamówienia składającego się z 4 etapów:</p>
      <p className='mb-2'>- Etap 1. Dane osobowe: Klient decyduje, czy chce złożyć zamówienie jako Gość lub zalogowany Użytkownik - w przypadku wcześniejszego zalogowania Klienta, ten etap jest pominięty.</p>
      <p className='mb-2'>- Etap 2. Adresy: W tym miejscu Klient wpisuje dane wymagane do procesu wysyłki oraz faktury.</p>
      <p className='mb-2'>- Etap 3. Sposób wysyłki: Klient dokonuje wyboru spośród przedstawionych mu ofert przewoźników. Akceptuje koszty wysyłki wygenerowane na podstawie adresu dostawy podanego w Etapie 2 oraz wielkości paczki zależnej od wybranych produktów. Koszty wysyłki zostają dodane do łącznej wartości zamówienia.</p>
      <p className='mb-2'>- Etap 4. Zapłata: Klient wskazuje formę płatności</p>
      <p className='mb-2'>4. Klient składając Zamówienie przez wysłanie formularza Zamówienia do Sprzedawcy, wybierając przycisk „Zamawiam z obowiązkiem zapłaty”. Każdorazowo przed złożeniem Zamówienia do Sprzedawcy, Klient jest informowany o łącznej Cenie za wybrany Towar i dostawie, jak też o wszystkich dodatkowych kosztach jakie jest zobowiązany ponieść w związku z Umową Sprzedaży. Wybór przycisku „Zamawiam z obowiązkiem zapłaty” jest równoznaczny ze złożeniem przez Klienta oświadczenia o zawarciu Umowy Sprzedaży.</p>
      <p className='mb-2'>5. Koszty dostawy zawierają się w kwocie od 25 zł do 3500 zł i jest zależny od wagi i gabarytu przesyłki.</p>
      <p className='mb-2'>6. Sprzedawca potwierdza Klientowi zawarcie i treść Umowy Sprzedaży w formie wiadomości elektronicznej.</p>
      <p className='mb-2'>§ 6. CENY I PROMOCJE</p>
      <p className='mb-2'>1. Sprzedawca zastrzega sobie prawo do możliwości aktualizowania Cen Towarów zamieszczonych w Sklepie. Ceny Towarów w zakresie Zamówień złożonych przez Klienta przed wprowadzeniem zmian, o których mowa w zdaniu poprzedzającym, pozostają dla Klienta wiążące.</p>
      <p className='mb-2'>2. Ceny w ramach Sklepu zawierają wszelkie należne podatki. Ceny nie zawierają kosztów przesyłki celem dostarczenia Towaru do Klienta, które są określane odrębnie. Sprzedawca może jednak w wybranym przez siebie okresie, w warunkach Promocji, określić próg minimalnej wartości Zamówienia, dla której przesyłka (dostawa) Towaru jest darmowa lub cena dostawy jest okresowo obniżona.</p>
      <p className='mb-2'>3. Produkt dostarczany poza granice Unii Europejskiej może rodzić obowiązek zapłaty przez Kupującego cła lub stosownego podatku zgodnie z przepisami obowiązującymi w kraju przeznaczenia.</p>
      <p className='mb-2'>4. Całkowita suma do zapłaty (obejmująca Cenę oraz w zależności od warunków Promocji także koszty dostawy) przez Klienta może zostać pomniejszona o określony rabat procentowy lub kwotowy poprzez wykorzystanie Kodu rabatowego. Sprzedawca określi zasady przyznawania i wykorzystania Kodów rabatowych w ramach odrębnych, ustalonych przez Sprzedawcę, warunków Promocji.</p>
      <p className='mb-2'>5. Promocje obowiązujące w Sklepie nie podlegają łączeniu, o ile postanowienia Promocji wyraźnie nie stanowią inaczej. Promocje mogą obejmować ograniczoną liczbę Towarów.</p>
      <p className='mb-2'>§ 7. ZASADY DOKONYWANIA PŁATNOŚCI I ZWROTU PŁATNOŚCI</p>
      <p className='mb-2'>1. Zamówienia złożone w Sklepie mogą być opłacone z góry (przed odbiorem Zamówienia), a w przypadku Zamówień dostarczanych na teren Polski (wyłącznie) także za pobraniem.</p>
      <p className='mb-2'>2. Płatności z góry mogą być uiszczane poprzez usługę PayPal, BLIK, karta płatniczą, przelew elektroniczny poprzez zewnętrzny system płatności mBank, obsługiwany przez firmę mBank S.A. z siedzibą w Warszawie, Stripe.</p>
      <p className='mb-2'>3. Termin płatności za Towary, w przypadku wyboru płatności z góry, wynosi 7 dni od dnia zawarcia Umowy Sprzedaży. Nieuiszczenie płatności w wyżej wskazanym terminie, pomimo upływu wyznaczonego dodatkowego 7 dniowego terminu, skutkuje rozwiązaniem Umowy Sprzedaży.</p>
      <p className='mb-2'>4. Jeżeli Konsument dokonał płatności kartą płatniczą lub przelewem elektronicznym, za pośrednictwem systemu płatności elektronicznych, zwrot należności (na przykład z tytułu odstąpienia od Umowy Sprzedaży) co do zasady następuje na kartę płatniczą lub na rachunek bankowy, z którego nastąpiła pierwotna płatność. Niemniej jednak Sprzedawca zastrzega sobie możliwość (w każdym przypadku) zwrotu należności na nr rachunku bankowego, wskazany przez Konsumenta w formularzu odstąpienia od umowy, o którym mowa w § 9 ust. 1 Regulaminu i/lub formularzu reklamacji, o którym mowa w § 10 ust. 4 Regulaminu, o ile Klient wyraźnie zgodził się na taki sposób zwrotu (podając nr konta) oraz zwrot w innej formie, nie wiąże się dla Konsumenta z żadnymi kosztami. W każdym przypadku zwrot środków wydatkowanych za pomocą środka płatniczego w formie karty podarunkowej (w przypadku wprowadzenia takiego produktu do Sklepu), może nastąpić wyłącznie na kartę podarunkową.</p>
      <p className='mb-2'>5. Nie ma możliwości dokonania zapłaty za części Zamówienia z góry, a za części Zamówienia przy odbiorze.</p>
      <p className='mb-2'>§ 8. DOSTAWA TOWARÓW</p>
      <p className='mb-2'>1. Realizacja Zamówienia rozpoczęta zostaje po otrzymaniu informacji o pozytywnym zakończeniu przeprowadzonej płatności.</p>
      <p className='mb-2'>2. Sprzedawca realizuje Zamówienia na terytorium Rzeczypospolitej Polskiej oraz poza jej granicami.</p>
      <p className='mb-2'>3. Termin realizacji Zamówienia rozumiany jako termin wysłania Towaru pod wskazany adres (liczony od momentu otrzymania informacji o pozytywnym zakończeniu przeprowadzonej płatności, a w przypadku płatności przy odbiorze liczony od zakończenia procesu przygotowywania Zamówienia) wynosi maksymalnie 30 (średnio jest to 1-7 Dni Roboczych), z tym zastrzeżeniem, że termin ten może ulec wydłużeniu ze względu na specyfikę realizacji danego zamówienia, kraj wysyłki w przypadku dużej ilości Zamówień etc.</p>
      <p className='mb-2'>4. Zamówione Towary są dostarczane do Klienta za pośrednictwem Partnera na adres wskazany w formularzu Zamówienia. W przypadku wyboru dostawy do paczkomatu lub punktu odbioru, adresem dostawy jest adres paczkomatu lub punktu odbioru wskazany przez Klienta w momencie składania Zamówienia.</p>
      <p className='mb-2'>5. Sprzedawca ma prawo do zmiany wybranego przez Klienta sposobu doręczenia Towaru, z zastrzeżeniem, iż Konsument nie może ponieść w zw. z tym dodatkowych kosztów. Sytuacja, o której mowa w niniejszym ustępie, dotyczy w szczególności sytuacji gdy współpraca pomiędzy Sprzedawcą a Partnerem, uległa rozwiązaniu z jakichkolwiek przyczyn.</p>
      <p className='mb-2'>6. Klient obowiązany jest zbadać doręczoną przesyłkę w czasie i w sposób przyjęty przy przesyłkach danego rodzaju. W razie stwierdzenia ubytku lub uszkodzenia przesyłki Klient winien niezwłocznie poinformować o tym fakcie Sprzedawcę. W sytuacji, o której mowa w zdaniu poprzedzającym, Konsument ma prawo żądać od przedstawiciela Partnera spisania właściwego protokołu zgodnie z treścią art. 74 ust. 4 ustawy z dnia 15 listopada 1984 r. Prawo przewozowe. W przypadku Klientów niebędących Konsumentami, Sprzedawca nie ponosi odpowiedzialności za uszkodzenia Towaru, powstałe z winy Partnera.</p>
      <p className='mb-2'>7. W przypadku nieobecności Klienta pod wskazanym przez niego adresem (podanym przy składaniu Zamówienia jako adres dostawy), przedstawiciel Partnera pozostawi awizo i/lub podejmie próbę kontaktu telefonicznego celem ustalenia terminu, w którym Klient będzie obecny. W przypadku zwrotnego odesłania zamówionego Towaru do Sklepu, Sprzedawca skontaktuje się z Klientem drogą elektroniczną lub telefonicznie, ponownie ustalając z Klientem termin i koszt dostawy.</p>
      <p className='mb-2'>8. Odbiór osobisty Towaru jest niemożliwy.</p>
      <p className='mb-2'>§ 9. ODSTĄPIENIE OD UMOWY</p>
      <p className='mb-2'>1. Konsument (wyłącznie), który zawarł Umowę Sprzedaży, może w terminie 14 dni odstąpić od niej bez podawania przyczyny. Bieg terminu na odstąpienie od Umowy Sprzedaży rozpoczyna się od chwili objęcia Towaru w posiadanie przez Konsumenta. Konsument może odstąpić od Umowy Sprzedaży, składając Sprzedawcy oświadczenie o odstąpieniu. Oświadczenie to może zostać złożone w szczególności pisemnie na adres Sprzedawcy lub za pośrednictwem poczty elektronicznej na adres Sprzedawcy. Oświadczenie można złożyć na formularzu, którego wzór został zamieszczony przez Sprzedawcę na Stronie. Do zachowania terminu wystarczy wysłanie oświadczenia przed jego upływem.</p>
      <p className='mb-2'>2. W przypadku odstąpienia od Umowy Sprzedaży, jest ona uważana za niezawartą. Jeśli Konsument złożył oświadczenie o odstąpieniu od Umowy Sprzedaży zanim Sprzedawca przyjął jego ofertę, oferta przestaje wiązać.</p>
      <p className='mb-2'>3. Sprzedawca ma obowiązek niezwłocznie, nie później jednak niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy Sprzedaży, zwrócić mu wszystkie dokonane przez niego płatności, w tym koszt dostawy Towaru do Konsumenta. Sprzedawca może wstrzymać się ze zwrotem płatności otrzymanych od Konsumenta do chwili otrzymania Towaru lub dostarczenia przez Konsumenta dowodu odesłania Towaru, w zależności od tego, które zdarzenie nastąpi wcześniej. Jeżeli Konsument korzystający z prawa do odstąpienia wybrał sposób dostarczenia Towaru inny niż najtańszy, zwykły sposób dostawy Zamówienia, oferowany przez Sprzedawcę, Sprzedawca nie jest zobowiązany do zwrotu Konsumentowi poniesionych przez niego dodatkowych kosztów.</p>
      <p className='mb-2'>4. Konsument ma obowiązek zwrócić Towar Sprzedawcy niezwłocznie, jednak nie później niż w terminie 14 dni od dnia, w którym odstąpił od Umowy Sprzedaży. Do zachowania terminu wystarczy odesłanie Towaru na adres ul. Marii Konopnickiej 13, 87-100 Toruń, przed upływem tego terminu. W wypadku odstąpienia, Konsumentem ponosi tylko bezpośrednie koszty zwrotu Towaru. Do zwracanego Towaru należy dołączyć oryginał paragonu fiskalnego lub faktury VAT.</p>
      <p className='mb-2'>5. Konsument ponosi koszty wysyłki Towaru do Sprzedawcy, a niniejsza klauzula winna być rozumiana jako poinformowanie Konsumenta o konieczności poniesienia tych kosztów przez.</p>
      <p className='mb-2'>6. W przypadku nieodebrania przesyłki przez Klienta/Konsumenta, w tym nieuiszczenia stosownych opłat (przesyłki wysyłane za pobraniem, przesyłki zagraniczne etc.), Sprzedawca ma prawo żądać od Klienta/ Konsument zwrotu wszelkich, poniesionych przez Sprzedawcę kosztów, co Klient/Konsument przyjmuje do wiadomości.</p>
      <p className='mb-2'>7. Konsument ponosi odpowiedzialność za zmniejszenie wartości Towaru będące wynikiem korzystania z niego w sposób wykraczający poza sposób konieczny do stwierdzenia charakteru, cech i funkcjonowania Towaru.</p>
      <p className='mb-2'>8. W przypadku odstąpienia od Umowy Sprzedaży przez Konsumenta, Sprzedawca dokonuje zwrotu płatności na zasadach określonych niniejszym Regulaminie.</p>
      <p className='mb-2'>9. Prawo odstąpienia od Umowy Sprzedaży w żadnym wypadku nie przysługuje Konsumentowi, który złożył Zamówienie Zindywidualizowane, Klientowi niebędącemu Konsumentem oraz w sytuacjach określonych przepisami powszechnie obowiązującego prawa.</p>
      <p className='mb-2'>§ 10. RĘKOJMIA</p>
      <p className='mb-2'>1. Sprzedawca zapewnia dostawę Towaru pozbawionego wad fizycznych i prawnych. Sprzedawca jest odpowiedzialny względem Klienta, jeżeli Towar ma wadę fizyczną lub prawną (rękojmia).</p>
      <p className='mb-2'>2. Jeżeli Towar ma wadę, Klient może złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od Umowy Sprzedaży, chyba że Sprzedawca niezwłocznie i bez nadmiernych niedogodności dla Klienta wymieni Towar wadliwy na wolny od wad albo wadę usunie. Ograniczenie to nie ma zastosowania, jeżeli Towar był już wymieniony lub naprawiony przez Sprzedawcę albo Sprzedawca nie uczynił zadość obowiązkowi wymiany Towaru na wolny od wad lub usunięcia wad.</p>
      <p className='mb-2'>3. Jeżeli Klientem jest Konsument, może zamiast zaproponowanego przez Sprzedawcę usunięcia wady żądać wymiany Towaru na wolny od wad albo zamiast wymiany Towaru żądać usunięcia wady, chyba że doprowadzenie Towaru do zgodności z Umową Sprzedaży, w sposób wybrany przez Klienta, jest niemożliwe albo wymagałoby nadmiernych kosztów w porównaniu ze sposobem proponowanym przez Sprzedawcę. Przy ocenie nadmierności kosztów uwzględnia się wartość Towaru wolnego od wad, rodzaj i znaczenie stwierdzonej wady, a także bierze się pod uwagę niedogodności, na jakie narażałby Klienta inny sposób zaspokojenia jego roszczeń.</p>
      <p className='mb-2'>4. Wszelkie reklamacje związane z Towarem i/lub realizacją Umowy Sprzedaży Klient może kierować w formie pisemnej na adres Sprzedawcy. Klient, który wykonuje uprawnienia z tytułu rękojmi, jest obowiązany dostarczyć wadliwy Towar na adres korespondencyjny Sprzedawcy tj. ul. ul. Marii Konopnickiej 13, 87-100 Toruń, Polska. W wypadku Klienta będącego Konsumentem koszt dostarczenia pokrywa Sprzedawca. Klient może również zgłosić Sprzedawcy reklamację w związku z korzystaniem z usług świadczonych drogą elektroniczną. Reklamacja może być złożona w formie elektronicznej i przesłana na adres zamowienia@new-story.pl W zgłoszeniu reklamacyjnym Klient winien zawrzeć opis zaistniałego problemu. Reklamację można złożyć na formularzu, którego wzór został zamieszczony przez Sprzedawcę na Stronie.</p>
      <p className='mb-2'>5. Sprzedawca odpowiada z tytułu rękojmi, jeżeli wada fizyczna zostanie stwierdzona przed upływem dwóch lat od wydania Towaru Klientowi. Roszczenie o usunięcie wady lub wymianę Towaru na wolny od wad przedawnia się z upływem roku, lecz termin ten nie może zakończyć się przed upływem terminu określonego w zdaniu poprzedzającym. W tym terminie Klient może odstąpić od Umowy Sprzedaży lub złożyć oświadczenie o obniżeniu Ceny z powodu wady Towaru. Jeżeli Klient żądał wymiany Towaru na wolny od wad lub usunięcia wady, termin do odstąpienia od Umowy Sprzedaży lub złożenia oświadczenia o obniżeniu Ceny rozpoczyna się z chwilą bezskutecznego upływu terminu do wymiany Towaru lub usunięcia wady.</p>
      <p className='mb-2'>6. Wady Towaru nie stanowi nieznaczna różnica pomiędzy odcieniu Towaru prezentowanym na Stronie Internetowej, a odcieniem Towaru dostarczonym do Konsumenta, w szczególności różnica, która wynika z przyczyn technicznych np. specyfikacji używanych przez Strony monitorów ekranowych etc.</p>
      <p className='mb-2'>7. Wobec Klientów niebędących Konsumentami, wyłącza się uprawnienia wynikające z tytułu rękojmi, chyba że Sprzedawca postanowi inaczej.</p>
      <p className='mb-2'>8. W przypadku Zamówień Zindywidualizowanych, otrzymany Towar może różnić się od informacji wskazanych na stronie internetowej Sklepu, w zakresie określonym przez samego klienta (np. kolor, rozmiar, rodzaj wykończenia, materiał) i różnica ta nie może stanowić podstawy reklamacji.</p>
      <p className='mb-2'>9. W przypadku Zamówień Indywidualnych Klient zobowiązany jest do wskazania wymiarów potrzebnych do realizacji Zamówienia, przy czym Sprzedawca w żadnym wypadku nie ponosi odpowiedzialności za wskazanie przez Klienta błędnych wymiarów i/lub za błędy popełnione przez Klienta podczas dokonywania pomiarów. Postanowienia ustepu 6 powyżej, stosuje się odpowiednio.</p>
      <p className='mb-2'>§ 11. DANE OSOBOWE</p>
      <p className='mb-2'>1. Zasady ochrony Danych Osobowych zamieszczone są w Polityce Prywatności. Polityka Prywatności opisana jest w odrębnym dokumencie.</p>
      <p className='mb-2'>2. Na Stronie stosowany jest mechanizm plików "Cookies". Stosowanie plików "Cookies" ma na celu poprawne działanie Strony na urządzeniach końcowych. Polityka „Cookies” opisana jest w Polityce Prywatności.</p>
      <p className='mb-2'>3. Publiczny charakter sieci Internet i korzystanie z usług świadczonych drogą elektroniczną wiązać się może z zagrożeniem pozyskania i modyfikowania danych Klientów przez osoby nieuprawnione, dlatego Sprzedawca zaleca Klientom stosowanie właściwych środków technicznych, które zminimalizują wskazane wyżej zagrożenia (programy antywirusowe). Sprzedawca nigdy nie zwraca się do Klientów z prośbą o udostępnienie mu danych innych niż konieczne do dokonania zawarcia Umowy Sprzedaży (imię i nazwisko, adres dostawy Towaru, numer telefonu oraz adres e-mail) oraz do przesyłania treści marketingowych (po wyrażeniu uprzedniej zgody Klientów).</p>
      <p className='mb-2'>§ 12. USŁUGI ŚWIADCZONE DROGĄ ELEKTRONICZNĄ</p>
      <p className='mb-2'>1. Sprzedawca świadczy na rzecz Klientów, drogą elektroniczną, 7 dni w tygodniu, 24 godziny na dobę, usługi nieodpłatne:</p>
      <p className='mb-2'>a) Prowadzenie Konta - usługa polega na zapewnieniu Klientowi dostępu do indywidualnego panelu, umożliwiającego Klientowi składanie Zamówień, śledzenie stanu realizacji Zamówień realizowanych oraz historii Zamówień już zrealizowanych,</p>
      <p className='mb-2'>b) Newsletter - usługa polega na przesyłaniu przez Sprzedawcę, na adres poczty elektronicznej, wiadomości w formie elektronicznej zawierającej informacje o Towarach (w tym Promocjach) i/lub nowych produktach i/lub usługach w ofercie Sprzedawcy, newsletter przesyłany jest przez Sprzedawcę do wszystkich Klientów, którzy dokonali subskrypcji).</p>
      <p className='mb-2'>2. Sprzedawca zastrzega sobie możliwość wyboru i zmiany rodzaju, form, czasu oraz sposobu udzielania dostępu do wybranych wymienionych usług nieodpłatnych, o czym poinformuje Klientów w sposób właściwy dla zmiany Regulaminu.</p>
      <p className='mb-2'>3. Z usługi Newsletter może skorzystać każdy Klient, który wprowadzi swój adres poczty elektronicznej, wykorzystując w tym celu formularz rejestracyjny udostępniony przez Sprzedawcę na Stronie. Po przesłaniu wypełnionego formularza rejestracyjnego, Klient niezwłocznie otrzymuje, drogą elektroniczną na adres poczty elektronicznej podany w formularzu rejestracyjnym link aktywacyjny, w celu potwierdzenia zapisania się na subskrypcję Newsleter. Z chwilą aktywacji linku przez Klienta zawarta zostaje umowa o świadczenie drogą elektroniczną usługi Newsletter. Klient może w każdej chwili zrezygnować z otrzymywania Newslettera przez wypisanie się z subskrypcji za pośrednictwem odnośnika zamieszczonego w każdej wiadomości elektronicznej wysłanej w ramach usługi Newsletter lub za pośrednictwem dezaktywacji odpowiedniego pola w Koncie.</p>
      <p className='mb-2'>4. Sprzedawca jest uprawniony do zablokowania dostępu do Konta, w przypadku działania przez Klienta na szkodę Sprzedawcy i/lub innych Klientów, a także gdy zablokowanie dostępu do Konta jest uzasadnione względami bezpieczeństwa. Sprzedawca zawiadamia Klienta o zablokowaniu dostępu do Konta.</p>
      <p className='mb-2'>§ 13. SIŁA WYŻSZA</p>
      <p className='mb-2'>1. Sprzedawca nie ponosi odpowiedzialności za skutki, których przy dochowaniu należytej staranności nie dało się przewidzieć tj. w przypadku zaistnienia tzw. siły wyższej.</p>
      <p className='mb-2'>2. Przez “siłę wyższą” rozumie się zdarzenia, które nie mogły być przewidziane w momencie zawarcia niniejszej Umowy, spowodowane zbiegiem okoliczności np. natury takie jak: wojna, zamieszki, pożar, warunki atmosferyczne, powódź, epidemia lub pandemia, trzęsienie ziemi i inne klęski żywiołowe oraz zakazy lub przepisy prawne wydane przez rząd, jak również generalne i branżowe strajki oficjalnie podjęte przez związki zawodowe.</p>
      <p className='mb-2'>§ 14. POSTANOWIENIA KOŃCOWE</p>
      <p className='mb-2'>1. Sprzedawcy przysługuje prawo okresowego zawieszenia działalności Sklepu w związku z przeprowadzaniem prac konserwacyjnych oraz całkowitego zaprzestania działalności Sklepu bez podania przyczyn.</p>
      <p className='mb-2'>2. Sprzedawca ponosi odpowiedzialność z tytułu niewykonania lub nienależytego wykonania Umowy Sprzedaży, z zastrzeżeniem, iż w przypadku Umów Sprzedaży, zawieranych z Klientami niebędących Konsumentami, Sprzedawca ponosi odpowiedzialność jedynie w przypadku umyślnego wyrządzenia szkody oraz w granicach rzeczywiście poniesionej, przez Klienta niebędącego Konsumentem, szkody.</p>
      <p className='mb-2'>3. Treść niniejszego Regulaminu może zostać utrwalona poprzez wydrukowanie, zapisanie na nośniku lub pobranie ze Strony w każdej chwili.</p>
      <p className='mb-2'>4. W przypadku powstania sporu na gruncie zawartej Umowy Sprzedaży, strony będą dążyły do rozwiązania sprawy polubownie. Prawem właściwym dla rozstrzygania wszelkich sporów powstałych na gruncie niniejszego Regulaminu jest prawo polskie.</p>
      <p className='mb-2'>5. Sprzedawca informuje Konsumenta o możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. Zasady dostępu do tych procedur dostępne są w siedzibach lub na stronach internetowych podmiotów uprawnionych do pozasądowego rozpatrywania sporów. Mogą nimi być w szczególności rzecznicy praw konsumenta lub Wojewódzkie Inspektoraty Inspekcji Handlowej, których lista jest dostępna na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów.</p>
      <p className='mb-2'>6. Uznanie poszczególnych postanowień niniejszego Regulaminu w sposób przewidziany prawem za nieważne bądź nieskuteczne, nie wpływa na ważność czy skuteczność pozostałych postanowień Regulaminu. W miejsce nieważnego postanowienia zastosowana będzie reguła, która jest najbliższa celom nieważnego postanowienia i całego Regulaminu.</p>
      <p className='mb-2'>7. Sprzedawca zastrzega sobie prawo zmiany niniejszego Regulaminu. Wszystkie Zamówienia przyjęte przez Sprzedawcę do realizacji, przed dniem wejścia w życie Regulaminu w nowym brzmieniu, są realizowane na podstawie Regulaminu, który obowiązywał w dniu składania Zamówienia przez Klienta. Zmiana Regulaminu wchodzi w życie w terminie 7 dni od dnia opublikowania na Stronie. Sprzedawca poinformuje Klientów, na 7 dni przed wejściem w życie nowego brzmienia Regulaminu, o zmianie Regulaminu za pomocą wiadomości przesłanej drogą elektroniczną, zawierającej odnośnik do tekstu zmienionego Regulaminu. W przypadku gdy Klient nie akceptuje nowej treści Regulaminu, obowiązany jest zawiadomić o tym fakcie Sprzedawcę, co skutkuje usunięciem Konta przez Sprzedawcę.</p>
      <p className='mb-2'>8. Umowy ze Sprzedawcą zawierane są w języku polskim a w przypadku Klientów niewładającymi językiem polskim, w języku angielskim.</p>
      <p className='mb-2'>9. Regulamin wchodzi w życie z dniem 19.09.2024 r.</p>
    </div>
  </section>
  )
};

export default Rules;
