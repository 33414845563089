import React, {createContext, useEffect, useState} from 'react';

export const CartContext = createContext();

const CartProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cart, setCart ] = useState([]);
  const [itemsAmount, setItemsAmount ] = useState(0);
  const [amount, setAmount ] = useState(0);
  const [total, setTotal ] = useState(0);
  useEffect(()=>{
    const amount = cart.reduce((a, c)=>{return a + c.amount}, 0);
    setItemsAmount(amount);
  }, [cart]);
  useEffect(()=>{
    const total = cart.reduce((a, c)=>{return a + c.price * c.amount}, 0);
    setTotal(total);
  }, [cart]);
  const addToCart = (item, id) => {
    const itemId = parseInt(id);
    const newItem = {...item[0], amount: 1};
    const cartItem = cart.find(item=>{return item.id === itemId});
    if (cartItem)
    {
      const newCart = cart.map(item=>{
        if ( item.id === itemId )
        {
          setAmount(cartItem.amount + 1);
          return {...item, amount: cartItem.amount + 1};
        }
        else
          return item;
      });
      setCart(newCart);
    }
    else
      setCart([...cart, newItem]);
    setIsOpen(true);
  };
  const removeFromCart = (id) => {
    const newCart = cart.filter((item)=>{return item.id !== id})
    setCart(newCart);
  };
  const handleInput = (e,id) => {
    const value = e.target.value;
    console.log(value, id);
    const cartItem = cart.find(item => {return item.id === id});
    if ( cartItem ) {
      const newCart = cart.map(item => {
        if ( item.id === id )
        {
          setAmount(value);
          return {...item, amount: value};
        }
        else
          return item;
      });
      setCart(newCart);
    }
  };
  const clearCart = () => {
    setCart([]);
  };
  return <CartContext.Provider value={{ 
    isOpen, 
    setIsOpen, 
    addToCart, 
    cart, 
    removeFromCart, 
    itemsAmount, 
    handleInput, 
    total, 
    clearCart,
  }}>
    {children}
  </CartContext.Provider>;
};

export default CartProvider;
