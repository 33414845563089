import React from 'react';

const Contact = () => {
  return (
  <section>
    <div className='container mx-auto pb-10'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Kontakt</h2>
      <p className='mb-2'>Fundacja New Story</p>
      <p className='mb-2'>KRS: 0000594965</p>
      <p className='mb-2'>NIP: 9562315251</p>
      <p className='mb-2'>TELEFON (PL): +48 536 700 235</p>
      <p className='mb-2'>E-MAIL (PL): zamowienia@new-story.pl</p>
      <p className='mb-2'>
      ADRES KORESPONDENCYJNY:<br/>
      ul. Marii Konopnickiej 13<br/>
      87-100 Toruń
      </p>
    </div>
  </section>
  )
};

export default Contact;
