import React from 'react';

const About = () => {
  return (
  <section>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>O nas</h2>
      <p className='mb-2'>Sklep internetowy New Story jest częścią naszej działalności jako podmiotu ekonomii społecznej. Założycielem sklepu jest Fundacja New Story z siedzibą w Toruniu. Działamy od 2015 roku. Naszą działalność dzielimy na  dwa główne sektory – jako organizacji pozarządowej wspierającej palące problemy społeczne, a także jako przedsiębiorstwa. Zyski z naszej działalności gospodarczej w całości przeznaczamy na działalność statutową.</p>
      <p className='mb-2'>Kupując u nas – zmieniasz świat na lepsze!</p>
      <p className='mb-2'>Więcej informacji o naszej działalności społecznej znajdziesz na stronie:</p>
      <p className='mb-2 text-white/60'><a href='http://new-story.pl' className='hover:text-white transition-all'>http://new-story.pl</a></p>
    </div>
  </section>
  )
};

export default About;
