import React from 'react';

import { Link } from 'react-router-dom';

const Returns = () => {
  return (
  <section>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Reklamacje i zwroty towaru</h2>
      <p className='mb-2 font-bold'>Reklamacja w sklepie NEW STORY</p>
      <p className='mb-2'>Jeżeli okaże się, że otrzymany produkt posiada błędy wynikające z wadliwego działania lub funkcjonowania (niespowodowane niepoprawnym użytkowaniem), zgłoś do nas ten problem! Gwarantujemy, że rozpatrzymy każdą reklamację indywidualnie, a przesłany produkt zostanie naprawiony, wymieniony na nowy lub inny, albo zwrócimy Ci za niego pieniądze!</p>
      <p className='mb-2'>W przypadku naszego błędu - koszt wysyłki ponosimy my! Pamiętaj jednak, że całkowity proces reklamacyjny trwa standardowo 14 dni kalendarzowych. Okres naprawy/wymiany natomiast może ulec wydłużeniu w przypadku braku dostępności wymaganych do naprawy materiałów. Jeżeli jednak produkt nie z naszej winy okazał się niepasujący (np. z powodu błędu podczas wyboru produktu), to w tym przypadku możesz ponieść niewielkie koszty (wysyłka + poprawki).</p>
      <p className='mb-2'>Po więcej informacji, zajrzyj do <span className='text-white/60'><Link to='/rules' className='hover:text-white transition-all'>Regulaminu</Link></span> naszego sklepu lub skontaktuj się z <span className='text-white/60'><Link to='/contact' className='hover:text-white transition-all'>Biurem Obsługi Klienta.</Link></span></p>
      <p className='mb-2 font-bold'>Zwroty lub wymiana towaru</p>
      <p className='mb-2'>Czy wiesz, że masz prawo do zwrotu zakupionego towaru w ciągu 14 dni kalendarzowych od daty dostarczenia paczki? Zwrot nie dotyczy jednak produktów na indywidualne zlecenie oraz posiadających modyfikacje wykonane według polecenia klienta. Nie odpowiadamy za niedopasowanie produktu, wynikające z błędów podczas wyboru przez klienta lub na skutek błędnych informacji przekazanych przez klienta.</p>
      <p className='mb-2'>Pamiętaj, że zwracany produkt nie powinien nosić śladów użytkowania. W przypadku towarów z widocznymi cechami zużycia – należy liczyć się z ewentualnym obniżeniem zwracanej kwoty. Więcej szczegółów na ten temat znajdziesz w Regulaminie naszego sklepu. Zakupione produkty odsyłasz na własny koszt. Zawsze staramy się rozwiązywać wszystkie problemy naszych klientów. Zależy nam, abyś był zadowolony ze swojego produktu, dlatego w razie jakichkolwiek pytań lub niepewności odnośnie wyboru – napisz do nas, a my chętnie udzielimy Ci wyczerpujących odpowiedzi na palące Cię problemy.</p>
      {/* Aby przejść do formularza zwrotu kliknij przycisk poniżej */}
    </div>
  </section>
  )
};

export default Returns;
