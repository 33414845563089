import React from 'react';

const Shipping = () => {
  return (
  <section>
    <div className='container mx-auto pb-10'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Opcje transportowe</h2>
      <ol className='mb-2 list-decimal pl-6'>
        <li>Przesyłka kurierska – 35 zł</li>
        <li>Przesyłka paletowa – 400 zł</li>
        <li>Odbiór osobisty – 0 zł</li>
        <li>Transport indywidualny – wycena indywidualna</li>
      </ol>
    </div>
  </section>
  )
};

export default Shipping;
